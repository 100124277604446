<!--  -->
<template>
  <div class='peixun'>
    <canvas id="canvas" ref="canvas" width="800" height="1200"></canvas>
  </div>
</template>

<script>
import Api from '../api/my'

const imgUrl = require('../assets/img/bbgg.jpg')
const zzUrl = require('../assets/img/zz.png')

export default {
  components: {},
  data() {
    return {
      
    };
  },
  computed: {},
  watch: {},
  created() {

  },
  mounted() {
    this.getTrainProve()
  },
  //方法集合
  methods: {
    getTrainProve () {
      Api.getTrainProve().then(res => {
        let info = res.data
        let canvas = this.$refs.canvas
        let ctx = canvas.getContext("2d");
        let img = new Image();
        img.src = imgUrl;
        img.onload = function() {
          ctx.drawImage(this,0,0,800,1200);
          ctx.font="20px Arial";
          ctx.fillText(info.name, 225, 595);
          ctx.fillText(info.card, 480, 595);
          ctx.fillText(info.leibie, 220, 652);
          ctx.fillText(info.pass_time, 480, 652);
          ctx.fillText(info.nian, 410, 1026);
          ctx.fillText(info.yue, 505, 1026);
          ctx.fillText(info.ri, 580, 1027);
          ctx.fillText(info.bianhao, 230, 1074);
          let img = new Image();
          img.src = zzUrl;
          img.onload = function() {
            ctx.drawImage(this,480,930,120,120);
            let img = new Image();
            img.src = info.head_img;
            img.onload = function () {
              ctx.drawImage(this,330,325,136,190);
            }
          }
        }
      })
    }
  },
}
</script>

<style lang='less' scoped>
.peixun {
  background-color: #fff;
  padding: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>